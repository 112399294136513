"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonSlider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_jss_1 = require("react-jss");
var ButtonSlider = function (_a) {
    var first = _a.first, second = _a.second, third = _a.third;
    var c = useStyles();
    return ((0, jsx_runtime_1.jsxs)("div", { className: c.buttonGroup, children: [(0, jsx_runtime_1.jsx)("button", { className: "".concat(c.button, " ").concat((first === null || first === void 0 ? void 0 : first.isActive) ? c.activeButton : undefined), onClick: first === null || first === void 0 ? void 0 : first.onClick, children: first === null || first === void 0 ? void 0 : first.text }), (0, jsx_runtime_1.jsx)("button", { className: "".concat(c.button, " ").concat((second === null || second === void 0 ? void 0 : second.isActive) ? c.activeButton : undefined), onClick: second === null || second === void 0 ? void 0 : second.onClick, children: second === null || second === void 0 ? void 0 : second.text }), (0, jsx_runtime_1.jsx)("button", { className: "".concat(c.button, " ").concat((third === null || third === void 0 ? void 0 : third.isActive) ? c.activeButton : undefined), onClick: third === null || third === void 0 ? void 0 : third.onClick, children: third === null || third === void 0 ? void 0 : third.text })] }));
};
exports.ButtonSlider = ButtonSlider;
var useStyles = (0, react_jss_1.createUseStyles)({
    buttonGroup: {
        display: 'flex',
        gap: 8,
        backgroundColor: 'rgba(248, 248, 248, 1)',
        padding: 8,
        borderRadius: 12,
        width: 'fit-content',
    },
    activeButton: {
        backgroundColor: 'white !important',
    },
    button: {
        padding: [5, 12],
        fontSize: 12,
        border: 'none',
        borderRadius: 8,
        cursor: 'pointer',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'white',
        },
        '&:active': {
            backgroundColor: 'white',
        },
    },
});
