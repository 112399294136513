"use strict";
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateDoctorScheduleForMonth = void 0;
var generateDoctorScheduleForMonth = function (year, month) {
    var getDaysInMonth = function (year, month) { return new Date(year, month + 1, 0).getDate(); };
    var events = [];
    var previousMonthDays = function (year, month) {
        var days = [];
        var firstDayOfMonth = new Date(year, month, 1).getDay();
        if (firstDayOfMonth !== 1) { // Если не понедельник
            var previousMonthLastDate = new Date(year, month, 0).getDate();
            var startDay = previousMonthLastDate - (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 2);
            for (var day = startDay; day <= previousMonthLastDate; day++) {
                days.push(new Date(year, month - 1, day));
            }
        }
        return days;
    };
    var nextMonthDays = function (year, month) {
        var days = [];
        var lastDayOfMonth = new Date(year, month + 1, 0).getDay();
        if (lastDayOfMonth !== 5) { // Если не пятница
            var day = 1;
            for (var i = lastDayOfMonth; i < 5; i++) {
                days.push(new Date(year, month + 1, day++));
            }
        }
        return days;
    };
    var addShift = function (date) {
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        var startOfDay = new Date(year, month, day, 8, 0, 0);
        var endOfDay = new Date(year, month, day, 17, 0, 0);
        var lunchStartHour = 12 + Math.floor(Math.random() * 3);
        var lunchStart = new Date(year, month, day, lunchStartHour, 0, 0);
        var lunchEnd = new Date(lunchStart);
        lunchEnd.setHours(lunchStart.getHours() + 1);
        var morningShift = {
            title: "".concat(startOfDay.getHours(), ":00 - ").concat(lunchStart.getHours(), ":00"),
            start: startOfDay,
            end: lunchStart,
        };
        var afternoonShift = {
            title: "".concat(lunchEnd.getHours(), ":00 - ").concat(endOfDay.getHours(), ":00"),
            start: lunchEnd,
            end: endOfDay,
        };
        events.push(morningShift, afternoonShift);
    };
    var daysInMonth = getDaysInMonth(year, month);
    for (var day = 1; day <= daysInMonth; day++) {
        var currentDate = new Date(year, month, day);
        var dayOfWeek = currentDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Исключить выходные
            addShift(currentDate);
        }
    }
    previousMonthDays(year, month).forEach(function (date) {
        if (date.getDay() !== 0 && date.getDay() !== 6) {
            addShift(date);
        }
    });
    nextMonthDays(year, month).forEach(function (date) {
        if (date.getDay() !== 0 && date.getDay() !== 6) {
            addShift(date);
        }
    });
    return events;
};
exports.generateDoctorScheduleForMonth = generateDoctorScheduleForMonth;
