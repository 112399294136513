"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddDoctor = exports.AddAbsence = exports.Settings = exports.Absence = exports.Doctors = exports.Detect = exports.Job = exports.Profile = exports.Calendar = exports.Main = exports.AuthSecond = exports.AuthFirst = void 0;
var AuthFirst_1 = require("./AuthFirst");
Object.defineProperty(exports, "AuthFirst", { enumerable: true, get: function () { return AuthFirst_1.AuthFirst; } });
var AuthSecond_1 = require("./AuthSecond");
Object.defineProperty(exports, "AuthSecond", { enumerable: true, get: function () { return AuthSecond_1.AuthSecond; } });
var Main_1 = require("./Main");
Object.defineProperty(exports, "Main", { enumerable: true, get: function () { return Main_1.Main; } });
var Calendar_1 = require("./Calendar");
Object.defineProperty(exports, "Calendar", { enumerable: true, get: function () { return Calendar_1.Calendar; } });
var Profile_1 = require("./Profile");
Object.defineProperty(exports, "Profile", { enumerable: true, get: function () { return Profile_1.Profile; } });
var Job_1 = require("./Job");
Object.defineProperty(exports, "Job", { enumerable: true, get: function () { return Job_1.Job; } });
var Detect_1 = require("./Detect");
Object.defineProperty(exports, "Detect", { enumerable: true, get: function () { return Detect_1.Detect; } });
var Doctors_1 = require("./Doctors");
Object.defineProperty(exports, "Doctors", { enumerable: true, get: function () { return Doctors_1.Doctors; } });
var Absence_1 = require("./Absence");
Object.defineProperty(exports, "Absence", { enumerable: true, get: function () { return Absence_1.Absence; } });
var Settings_1 = require("./Settings");
Object.defineProperty(exports, "Settings", { enumerable: true, get: function () { return Settings_1.Settings; } });
var AddAbsence_1 = require("./AddAbsence");
Object.defineProperty(exports, "AddAbsence", { enumerable: true, get: function () { return AddAbsence_1.AddAbsence; } });
var AddDoctor_1 = require("./AddDoctor");
Object.defineProperty(exports, "AddDoctor", { enumerable: true, get: function () { return AddDoctor_1.AddDoctor; } });
