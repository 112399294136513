"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearAccountData = exports.setAccountData = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    id: undefined,
    fullName: undefined,
    dateOfBirth: undefined,
    position: undefined,
    specialization: undefined,
    token: undefined,
};
var chatWithSlice = (0, toolkit_1.createSlice)({
    name: 'account',
    initialState: initialState,
    reducers: {
        setAccountData: function (state, action) {
            state.id = action.payload.id;
            state.fullName = action.payload.fullName;
            state.position = action.payload.position;
            state.specialization = action.payload.specialization;
            state.token = action.payload.token;
            state.dateOfBirth = action.payload.dateOfBirth;
        },
        clearAccountData: function (state) {
            ;
            (state.fullName = undefined),
                (state.id = undefined),
                (state.dateOfBirth = undefined),
                (state.position = undefined),
                (state.specialization = undefined),
                (state.token = undefined);
        },
    },
});
exports.setAccountData = (_a = chatWithSlice.actions, _a.setAccountData), exports.clearAccountData = _a.clearAccountData;
exports.default = chatWithSlice.reducer;
