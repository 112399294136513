"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthFirst = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ui_kit_1 = require("../../ui-kit");
var react_jss_1 = require("react-jss");
var logos_1 = require("./logos");
var react_router_dom_1 = require("react-router-dom");
var AuthFirst = function () {
    var c = useStyles();
    var navigate = (0, react_router_dom_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)("div", { className: c.root, children: [(0, jsx_runtime_1.jsx)(ui_kit_1.BlueButton, { text: '\u0420\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044C \u0440\u0435\u0444\u0435\u0440\u0435\u043D\u0441-\u0446\u0435\u043D\u0442\u0440\u0430', onClick: function () {
                    navigate('/auth/head');
                }, children: (0, jsx_runtime_1.jsx)(logos_1.Head, {}) }), (0, jsx_runtime_1.jsx)(ui_kit_1.BlueButton, { text: '\u0412\u0440\u0430\u0447', onClick: function () {
                    navigate('/auth/doctor');
                }, children: (0, jsx_runtime_1.jsx)(logos_1.Doctor, {}) }), (0, jsx_runtime_1.jsx)(ui_kit_1.BlueButton, { text: '\u041A\u0430\u0434\u0440\u043E\u0432\u044B\u0439 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442', onClick: function () {
                    navigate('/auth/HR');
                }, children: (0, jsx_runtime_1.jsx)(logos_1.HR, {}) })] }));
};
exports.AuthFirst = AuthFirst;
var useStyles = (0, react_jss_1.createUseStyles)({
    root: {
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 15,
    },
});
