"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_jss_1 = require("react-jss");
var Input = function (_a) {
    var placeholder = _a.placeholder, readOnly = _a.readOnly, text = _a.text, setText = _a.setText;
    var c = useStyles();
    return ((0, jsx_runtime_1.jsx)("input", { className: c.input, placeholder: placeholder, readOnly: readOnly, onChange: function (event) { return setText === null || setText === void 0 ? void 0 : setText(event.target.value); }, value: text }));
};
exports.Input = Input;
var useStyles = (0, react_jss_1.createUseStyles)({
    input: {
        marginTop: 5,
        width: '100%',
        padding: [15, 20],
        backgroundColor: '#F8F8F8',
        borderRadius: 12,
        marginBottom: 20,
        boxSizing: 'border-box',
        '&::placeholder': {
            color: 'rgba(121, 121, 121, 1)',
            fontSize: 14,
        },
    },
});
