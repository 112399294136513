"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Detect = exports.Doctors = exports.Warning = exports.Settings = exports.Profile = exports.Job = exports.Calendar = void 0;
var Calendar_1 = require("./Calendar");
Object.defineProperty(exports, "Calendar", { enumerable: true, get: function () { return Calendar_1.Calendar; } });
var Job_1 = require("./Job");
Object.defineProperty(exports, "Job", { enumerable: true, get: function () { return Job_1.Job; } });
var Profile_1 = require("./Profile");
Object.defineProperty(exports, "Profile", { enumerable: true, get: function () { return Profile_1.Profile; } });
var Settings_1 = require("./Settings");
Object.defineProperty(exports, "Settings", { enumerable: true, get: function () { return Settings_1.Settings; } });
var Warning_1 = require("./Warning");
Object.defineProperty(exports, "Warning", { enumerable: true, get: function () { return Warning_1.Warning; } });
var Doctors_1 = require("./Doctors");
Object.defineProperty(exports, "Doctors", { enumerable: true, get: function () { return Doctors_1.Doctors; } });
var Detect_1 = require("./Detect");
Object.defineProperty(exports, "Detect", { enumerable: true, get: function () { return Detect_1.Detect; } });
