"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_jss_1 = require("react-jss");
var react_redux_1 = require("react-redux");
var ui_kit_1 = require("../../ui-kit");
var api_1 = require("../../api");
var Profile = function () {
    var c = useStyles();
    var account = (0, react_redux_1.useSelector)(function (state) { return state.account; });
    var _a = (0, react_1.useState)(null), skills = _a[0], setSkills = _a[1];
    (0, react_1.useEffect)(function () {
        if (account.id && account.token) {
            (0, api_1.getDoctor)(account.id, account.token).then(function (res) {
                setSkills(res.skills);
                return;
            });
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", { className: c.root, children: [(0, jsx_runtime_1.jsx)("h1", { className: c.header, children: "\u041B\u0438\u0447\u043D\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F" }), (0, jsx_runtime_1.jsxs)("div", { className: c.content, children: [(0, jsx_runtime_1.jsx)("p", { className: c.title, children: "\u0424\u0418\u041E" }), (0, jsx_runtime_1.jsx)(ui_kit_1.Input, { placeholder: account.fullName, readOnly: true }), (0, jsx_runtime_1.jsx)("p", { className: c.title, children: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }), (0, jsx_runtime_1.jsx)(ui_kit_1.Input, { placeholder: account.position, readOnly: true }), (0, jsx_runtime_1.jsx)("p", { className: c.title, children: "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u044F" }), (0, jsx_runtime_1.jsx)(ui_kit_1.Input, { placeholder: account.specialization, readOnly: true }), (0, jsx_runtime_1.jsx)("p", { className: c.title, children: "\u041A\u043E\u043C\u043F\u0435\u0442\u0435\u043D\u0446\u0438\u0438" }), (0, jsx_runtime_1.jsx)(ui_kit_1.Check, { readOnly: true, text: skills === null || skills === void 0 ? void 0 : skills.primary_skill }), skills === null || skills === void 0 ? void 0 : skills.secondary_skills.map(function (el, i) { return (0, jsx_runtime_1.jsx)(ui_kit_1.Check, { readOnly: true, text: el }, i); })] })] }));
};
exports.Profile = Profile;
var useStyles = (0, react_jss_1.createUseStyles)({
    root: {
        padding: [40, 80, 40, 50],
        fontFamily: 'Inter, sans-serif',
    },
    header: {
        fontSize: 28,
        fontWeight: 600,
    },
    title: {
        fontSize: 14,
        paddingLeft: 10,
    },
    content: {
        marginTop: 35,
    },
});
