"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Absence = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ui_kit_1 = require("../../ui-kit");
var react_jss_1 = require("react-jss");
var Card_1 = require("./Card");
var react_router_dom_1 = require("react-router-dom");
var Absence = function () {
    var c = useStyles();
    var navigate = (0, react_router_dom_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)("div", { className: c.root, children: [(0, jsx_runtime_1.jsxs)("div", { className: c.heading, children: [(0, jsx_runtime_1.jsx)(ui_kit_1.SearchInput, {}), (0, jsx_runtime_1.jsx)(ui_kit_1.Dropdown, { text: '\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u044F' }), (0, jsx_runtime_1.jsx)(ui_kit_1.ColoredButton, { text: '\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043D\u0435\u043F\u0440\u0435\u0434\u0432\u0438\u0434\u0435\u043D\u043D\u043E\u0435 \u043E\u0431\u0441\u0442\u043E\u044F\u0442\u0435\u043B\u044C\u0441\u0442\u0432\u043E', color: 'red', onClick: function () { return navigate('/absence/add'); } })] }), (0, jsx_runtime_1.jsxs)("div", { className: c.cards, children: [(0, jsx_runtime_1.jsx)(Card_1.Card, { type: 'sickness' }), (0, jsx_runtime_1.jsx)(Card_1.Card, { type: 'vacation' }), (0, jsx_runtime_1.jsx)(Card_1.Card, { type: 'absence' })] })] }));
};
exports.Absence = Absence;
var useStyles = (0, react_jss_1.createUseStyles)({
    root: {
        padding: [30, 50],
    },
    heading: {
        display: 'flex',
        gap: 10,
    },
    cards: {
        marginTop: 25,
        gap: 10,
        display: 'flex',
    },
});
