"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Doctors = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_jss_1 = require("react-jss");
var Card_1 = require("./Card");
// import {data} from './Card/mock'
var ui_kit_1 = require("../../ui-kit");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var api_1 = require("../../api");
var react_router_dom_1 = require("react-router-dom");
var Doctors = function () {
    var c = useStyles();
    var account = (0, react_redux_1.useSelector)(function (state) { return state.account; });
    var _a = (0, react_1.useState)(null), data = _a[0], setDoctors = _a[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        if (account.token) {
            (0, api_1.getDoctors)(account.token).then(function (res) {
                setDoctors(res);
                return;
            }).catch(function () {
                setDoctors([{
                        "id": 1,
                        "full_name": "Аристов Владислав Евгеньевич",
                        "date_of_birth": "2003-05-23",
                        "position": "",
                        "specialization": ""
                    }, {
                        "id": 2,
                        "full_name": "Петров Петр Петрович",
                        "date_of_birth": "2003-05-23",
                        "position": "",
                        "specialization": ""
                    }]);
            });
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", { className: c.root, children: [(0, jsx_runtime_1.jsxs)("div", { className: c.heading, children: [(0, jsx_runtime_1.jsx)(ui_kit_1.SearchInput, {}), (0, jsx_runtime_1.jsx)(ui_kit_1.Dropdown, { text: '\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u044F' }), (0, jsx_runtime_1.jsx)(ui_kit_1.Dropdown, { text: '\u0420\u0430\u0431\u043E\u0442\u0430\u0435\u0442' }), (0, jsx_runtime_1.jsx)(ui_kit_1.ColoredButton, { text: '\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043D\u043E\u0432\u043E\u0433\u043E \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u0430', onClick: function () { return navigate('/add_doctors'); } })] }), (0, jsx_runtime_1.jsx)("div", { className: c.cards, children: data &&
                    data.map(function (el, i) { return ((0, jsx_runtime_1.jsx)(Card_1.DoctorCard, { busy: 'available', fullName: el.full_name, jobTitle: el.position, competitions: el.specialization }, i)); }) })] }));
};
exports.Doctors = Doctors;
var useStyles = (0, react_jss_1.createUseStyles)({
    root: {
        padding: [30, 80, 30, 50],
    },
    heading: {
        display: 'flex',
        gap: 10,
    },
    cards: {
        display: 'flex',
        gap: 10,
        marginTop: 25,
        flexWrap: 'wrap',
    },
});
