"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Search_1 = require("./Search");
var react_jss_1 = require("react-jss");
var SearchInput = function () {
    var c = useStyles();
    return ((0, jsx_runtime_1.jsxs)("div", { className: c.pos, children: [(0, jsx_runtime_1.jsx)("input", { className: c.search, placeholder: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0442\u0435\u043A\u0441\u0442...' }), (0, jsx_runtime_1.jsx)("div", { className: c.searchLogo, children: (0, jsx_runtime_1.jsx)(Search_1.Search, {}) })] }));
};
exports.SearchInput = SearchInput;
var useStyles = (0, react_jss_1.createUseStyles)({
    pos: {
        position: 'relative',
        width: 'fit-content',
    },
    searchLogo: {
        position: 'absolute',
        right: 20,
        top: 15,
    },
    search: {
        backgroundColor: 'rgba(248, 248, 248, 1)',
        borderRadius: 12,
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 14,
        '& ::placeholder': {
            color: 'rgba(121, 121, 121, 1)',
        },
    },
});
